<template>
  <div>
    <b-container>
      <b-row align-v="center">
        <b-col>
          <b-link v-show="!childRegistrationInProgress" @click="openRegisterChildForm" title="Register Child">
            <b-icon-person-plus class="h2 mb-2"/>
          </b-link>
          <b-spinner v-show="childRegistrationInProgress" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table striped hover :fields="fields" :items="$store.state.kids">
            <template #cell(name)="row">
              {{ row.value }}
            </template>
            <template #cell(contactEmail)="row">
              <span role="button" class="" :title="row.value">{{ redactEmail(row.value) }}</span>
            </template>
            <template #cell(status)="row">
              <b-link v-b-popover.hover.top.html="statusDetails(row.item)" title="Status Details">
                <b-icon-question-circle variant="secondary" v-show="row.value === 'u'"/>
                <b-icon-emoji-smile variant="success" v-show="row.value === 'a'"/>
                <b-icon-thermometer-half variant="danger" v-show="row.value === 'r'"/>
              </b-link>
            </template>
            <template #cell(actions)="row">
              <b-link @click="openInfoEdit(row.item, group)" :title="'Update ' + row.item.name + ' info'"
                      :alt="'Update ' + row.item.name + ' info'">
                <b-icon-pencil variant="warning"/>
              </b-link>
              <b-link v-show="$store.state.groups.length > 1" class="ml-2" @click="openSwitchGroupPopup(row.item, group)"
                      :title="'Move ' + row.item.name + ' to a different group'"
                      :alt="'Move ' + row.item.name + ' to a different group'">
                <b-icon-truck variant="warning"/>
              </b-link>
              <b-link class="ml-2" title="Regenerate QR Code" alt="Regenerate QR Code" @click="confirmCodeRegeneration(row.item)">
                <b-icon-bootstrap-reboot variant="info" />
              </b-link>
              <b-link v-show="!childRegistrationRemovalInProgress" class="ml-2" @click="confirmChildRegistrationRemoval(row.item)"
                      :title="'Remove ' + row.item.name + '\'s record'"
                      :alt="'Remove ' + row.item.name + '\'s record'">
                <b-icon-person-dash variant="danger"/>
              </b-link>
              <b-spinner v-show="childRegistrationRemovalInProgress"/>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-container>
    <div id="kidsModals">
      <b-modal id="registerChildModal" title="Add Child" ok-title="Add" cancel-title="Cancel" @ok="addChild"
               :ok-disabled="childInfoIncomplete">
        <b-container>
          <b-row>
            <b-col>
              <b-form-input v-b-popover.hover.top.html="friendlyNameDetails" v-model="childName" placeholder="Enter child's friendly name here" required></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input v-model="contactEmail" placeholder="Enter parent/guardian email to receive QR code"
                            required></b-form-input>
              <i>(an email will be sent to this email containing child's unique QR code)</i>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="updateChildInfoModal" title="Update Child Info" ok-title="Update" cancel-title="Cancel"
               @ok="updateChildInfo" :ok-disabled="childInfoIncomplete">
        <b-container>
          <b-row>
            <b-col>
              <b-form-input v-model="childName" placeholder="Enter child's full name here" required></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-input v-model="contactEmail" placeholder="Enter parent/guardian email to receive QR code"
                            required></b-form-input>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="switchGroupModal" title="Move to different group" :ok-disabled="targetGroupNotSelected" ok-title="Go"
               cancel-title="Cancel" @ok="moveChildToNewGroup">
        <p class="my-4">Which group should {{ childName }} be moved to?</p>
        <b-form-select v-model="targetGroup" class="mb-3">
          <template #first>
            <b-form-select-option :value="null" disabled>-- Please select an option --</b-form-select-option>
          </template>
          <b-form-select-option v-for="(ag, idx) in availableGroups" :key="idx" :value="ag">{{ ag.name }}
          </b-form-select-option>
        </b-form-select>
      </b-modal>
      <b-modal id="regenerateChildCodeModal" title="Confirmation Required" ok-title="Yes" cancel-title="No" @ok="regenerateCodeForChild">
        <p class="my-4">Are you sure you want to regenerate QR code for <b>{{childName}}</b>? The new code will be sent to <i>{{contactEmail}}.</i></p>
      </b-modal>
      <b-modal id="removeChildRegistrationModal" title="Confirmation Required" ok-title="Yes" cancel-title="No" @ok="removeChildRegistration">
        <p class="my-4">Are you sure you want to remove the record for <b>{{childName}}</b>?</p>
      </b-modal>
    </div>
  </div>
</template>

<script>

const statusMap = {
  u: 'Unknown',
  a: 'Good To Go',
  r: 'Hero needs to recover at home'
}

export default {
  name: 'Kids',
  props: {
    'daycareGroup': {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: [
        {key: 'name', label: 'Name', sortable: true, sortDirection: 'asc'},
        {key: 'contactEmail', label: 'Contact Email', sortable: false},
        {key: 'status', label: 'Status', sortable: false},
        {key: 'actions', label: 'Actions', sortable: false}
      ],
      group: this.daycareGroup,
      childName: '',
      contactEmail: '',
      childId: null,
      targetGroup: null,
      childRegistrationInProgress: false,
      childRegistrationRemovalInProgress: false,
      friendlyNameDetails: 'A child\'s friendly name is meant to uniquely identify him/her within the organization without necessarily using the legal name. The legal name is not necessary here and should be avoided if possible. For example, one may use Joanne D. as a friendly name instead of Joanne Doe (legal name).'
    }
  },
  methods: {
    statusDetails(kid) {
      let statusDetails = '<div><b>Current status:</b>' + statusMap[kid.status] + '</div>'
      if (kid.statusDate && kid.statusDate.length >= 10) {
        statusDetails += '<div><b>Last assessment has been on:</b>&nbsp;' + kid.statusDate + '</div>'
      }
      if (kid.statusDetails && kid.statusDetails.length > 0) {
        statusDetails += '<div><b>Last results:</b>&nbsp;' + kid.statusDetails + '</div>'
      }
      return statusDetails
    },
    removeChildRegistration() {
      this.childRegistrationRemovalInProgress = true
      this
          .$store
          .dispatch('removeChildRegistration', [this.$keycloak.token, this.childId])
          .then(() => {
            this.$bvToast.toast( this.childName + '\'s registration has been deleted.', {
              title: `Delete success`,
              variant: 'warning',
              solid: true,
              autoHideDelay: 1500
            })
            this.childRegistrationRemovalInProgress = false
          })
          .catch((err) => {
            this.$bvToast.toast(err, {
              title: `Delete failed`,
              variant: 'danger',
              solid: true,
              autoHideDelay: 3000
            })
            this.childRegistrationRemovalInProgress = false
          })
    },
    confirmChildRegistrationRemoval(rowItem) {
      this.initChildInfo(rowItem)
      this.$bvModal.show('removeChildRegistrationModal')
    },
    notifyUserThatCodeHasBeenGenerated(regenerated) {
      const title = (regenerated === true) ? 'Code Regenerated' : 'Code Generated'
      let msg = 'QR code for ' + this.childName + ' has been '
      msg += (regenerated === true) ? 'regenerated' : 'generated'
      msg += 'and sent to ' + this.contactEmail + '.'
      this.$bvToast.toast(msg, {
        title: title,
        variant: 'success',
        solid: true,
        autoHideDelay: 1500
      })
    },
    regenerateCodeForChild() {
      this
          .$store
          .dispatch('regenerateCode', [this.$keycloak.token, this.childId])
          .then(() => this.notifyUserThatCodeHasBeenGenerated(true) )
          .catch((err) =>
              this.$bvToast.toast(err, {
                title: `Code regeneration failed`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
          )
    },
    resetChildInfo() {
      this.childCode = null
      this.childName = ''
      this.contactEmail = ''
    },
    initChildInfo(rowItem) {
      this.childId = rowItem.id
      this.childName = rowItem.name
      this.contactEmail = rowItem.contactEmail
    },
    confirmCodeRegeneration(rowItem) {
      this.initChildInfo(rowItem)
      this.$bvModal.show('regenerateChildCodeModal')
    },
    moveChildToNewGroup() {
      this
          .$store
          .dispatch('moveChildToGroup', [this.$keycloak.token, this.targetGroup.id, this.childId])
          .then(() =>
              this.$bvToast.toast(this.childName + ' has been moved to ' + this.targetGroup.name, {
                title: `Child Group Changed`,
                variant: 'success',
                solid: true,
                autoHideDelay: 1500
              })
          )
          .catch((err) =>
              this.$bvToast.toast(err, {
                title: `Child Group Change Failed`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
          )
    },
    openSwitchGroupPopup(item) {
      this.initChildInfo(item)
      this.targetGroup = null
      this.$bvModal.show('switchGroupModal')
    },
    redactWord(word) {
      return word[0] + "*".repeat(word.length - 2) + word.slice(-1)
    },
    redactEmail(email) {
      if (email && email.length > 4 && email.indexOf('@') > 0) {
        const arr = email.split("@");
        return this.redactWord(arr[0]) + "@" + this.redactWord(arr[1]);
      } else {
        return ''
      }
    },
    updateChildInfo() {
      this
          .$store
          .dispatch('updateChildInfo', [this.$keycloak.token, this.childId, {
            name: this.childName,
            contactEmail: this.contactEmail
          }])
          .then((child) =>
              this.$bvToast.toast(child.name + ' info edited.', {
                title: `Child Info Updated`,
                variant: 'success',
                solid: true,
                autoHideDelay: 1500
              })
          )
          .catch((err) =>
              this.$bvToast.toast(err, {
                title: `Child Info Update Failed`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
          )
    },
    openInfoEdit(rowItem) {
      this.initChildInfo(rowItem)
      this.$bvModal.show('updateChildInfoModal')
    },
    notifyUserThatChildRegistrationFailed(err) {
      this.$bvToast.toast(err, {
        title: `Child Registration Failed`,
        variant: 'danger',
        solid: true,
        autoHideDelay: 3000
      })
    },
    notifyUserThatChildRegistrationSucceeded(child) {
      this.$bvToast.toast(child.name + ' has been added to ' + this.group.name + '.', {
        title: `New Child Added`,
        variant: 'success',
        solid: true,
        autoHideDelay: 1500
      })
    },
    notifyUserThatSendingTheCodeToContactEmailHasFailed(contactEmail) {
      this.$bvToast.toast('Failed to generate and send unique QR code to contact email at ' + contactEmail, {
        title: 'Unique Code Generation Failed',
        variant: 'danger',
        solid: true,
        autoHideDelay: 5000
      })
    },
    addChild() {
      let childRegistrationSucceeded = false
      this.childRegistrationInProgress = true
      this
          .$store
          .dispatch('registerChildInGroup', [this.$keycloak.token, this.group.id, {
            name: this.childName,
            contactEmail: this.contactEmail
          }])
          .then((child) => {
            this.notifyUserThatChildRegistrationSucceeded(child)
            childRegistrationSucceeded = true
            this.childRegistrationInProgress = false
            return this
                .$store
                .dispatch('regenerateCode', [this.$keycloak.token, child.id])
                .then(() => this.notifyUserThatCodeHasBeenGenerated(false))
          })
          .catch((err) => {
            if (childRegistrationSucceeded === true) {
              this.notifyUserThatSendingTheCodeToContactEmailHasFailed(this.contactEmail)
            } else {
              this.notifyUserThatChildRegistrationFailed(err)
            }
            this.childRegistrationInProgress = false
          })
    },
    openRegisterChildForm() {
      this.resetChildInfo()
      this.$bvModal.show('registerChildModal')
    }
  },
  computed: {
    availableGroups() {
      return this.$store.state.groups.filter((g) => g.name !== this.group.name).sort((g1, g2) => g1.idx - g2.idx)
    },
    targetGroupNotSelected() {
      return this.targetGroup === null || this.targetGroup.length === 0
    },
    childInfoIncomplete() {
      return this.childName.length === 0 || this.contactEmail.length === 0
    }
  },
  mounted() {
    this.$store.dispatch('findKidsInGroup', [this.$keycloak.token, this.group.id])
  }
}

</script>

<style scoped>
</style>
