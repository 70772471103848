import Vue from 'vue'
import Vuex from 'vuex'
import ApiService from "@/service/ApiService";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        groups: [],
        kids: [],
        userAgreedToTerms: false,
        profile: null
    },
    mutations: {
        SET_PROFILE(state, profile) {
            state.profile = profile
            state.userAgreedToTerms = profile.acceptedTerms
        },
        UPDATE_KIDS_INFO(state, kids) {
            const updatesMap = {}
            kids.forEach((k) => updatesMap[k.id] = k)
            state
                .kids
                .filter((k) => updatesMap[k.id])
                .forEach((k) => {
                    const u = updatesMap[k.id]
                    k.name = u.name
                    k.contactEmail = u.contactEmail
                    k.lastUpdated = u.lastUpdated
                    k.code = u.code
                    k.status = u.status
                    k.statusDate = u.statusDate
                    k.statusDetails = u.statusDetails
                })
        },
        UPDATE_CHILD_REGISTRATION(state, child) {
            state
                .kids
                .filter((c) => c.id === child.id)
                .forEach((c) => {
                    c.name = child.name
                    c.contactEmail = child.contactEmail
                    c.lastUpdated = child.lastUpdated
                    c.code = child.code
                    c.status = child.status
                    c.statusDate = child.statusDate
                    c.statusDetails = child.statusDetails
                })
        },
        REMOVE_CHILD_RECORD(state, childId) {
            state.kids = state.kids.filter((c) => c.id !== childId).sort((r1, r2) => r1.name.localeCompare(r2.name))
        },
        REGISTER_CHILD_IN_GROUP(state, childRecord) {
            state.kids.push(childRecord)
        },
        UPDATE_GROUP(state, group) {
            state
                .groups
                .filter((g) => g.id === group.id)
                .forEach((g) => {
                    g.name = group.name
                    g.lastUpdated = group.lastUpdated
                    g.idx = group.idx
                })
        },
        DELETE_GROUP(state, groupId) {
            state.groups = state.groups.filter((g) => g.id !== groupId).sort((g1, g2) => g1.idx - g2.idx)
        },
        SET_KIDS(state, kids) {
            state.kids = kids.sort((r1, r2) => r1.name.localeCompare(r2.name))
        },
        RESET_KIDS(state) {
            state.kids = []
        },
        ADD_GROUP(state, group) {
            state.groups.push(group)
            state.groups = state.groups.sort((g1, g2) => g1.idx - g2.idx)
        },
        FETCH_ALL_GROUPS(state, groups) {
            state.groups = groups.sort((g1, g2) => g1.idx - g2.idx)
        }
    },
    actions: {
        noteUserAgreement(context, accessToken) {
            ApiService
                .notifyUserAgreedToTerms(accessToken)
                .then((profile) => context.commit('SET_PROFILE', profile))
        },
        loadProfile(context, accessToken) {
            return new Promise(
                (resolve, reject) => ApiService
                    .loadProfile(accessToken)
                    .then((profile) => {
                        context.commit('SET_PROFILE', profile)
                        resolve(profile)
                    })
                    .catch(reject)
            )
        },
        findStatusUpdates(context, accessToken) {
            const clientStatuses = context.state.kids.map((k) => {return { id: k.id, lastUpdated: k.lastUpdated}})
            if (clientStatuses.length === 0) {
                return Promise.resolve()
            }
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .findStatusUpdates(accessToken, clientStatuses)
                        .then((kids) => {
                            context.commit('UPDATE_KIDS_INFO', kids)
                            resolve()
                        })
                        .catch((err) => {
                            console.error('Api Status call failed', err)
                            reject(err)
                        })

                }
            )
        },
        regenerateCode(context, [accessToken, childId]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .regenerateCode(accessToken, childId)
                        .then((child) => {
                            context.commit('UPDATE_CHILD_REGISTRATION', child)
                            resolve()
                        })
                        .catch(() => reject('An internal issue has prevented the child record from being moved to a different group. Please try again later.'))
                }
            )
        },
        moveChildToGroup(context, [accessToken, groupId, childId]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .moveChildToGroup(accessToken, groupId, childId)
                        .then(() => {
                            context.commit('REMOVE_CHILD_RECORD', childId)
                            resolve()
                        })
                        .catch(() => reject('An internal issue has prevented the child record from being moved to a different group. Please try again later.'))
                }
            )
        },
        updateChildInfo(context, [accessToken, childId, updatedProps]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .updateChildInfo(accessToken, childId, updatedProps)
                        .then((child) => {
                            context.commit('UPDATE_CHILD_REGISTRATION', child)
                            resolve(child)
                        })
                        .catch(() => reject('An internal issue has prevented the child registration information from being updated. Please try again later.'))
                }
            )
        },
        removeChildRegistration(context, [accessToken, childId]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .removeChildRegistration(accessToken, childId)
                        .then(() => {
                            context.commit('REMOVE_CHILD_RECORD', childId)
                            resolve()
                        })
                        .catch(() => reject('An internal issue has prevented the child record from being removed. Please try again later.'))
                }
            )
        },
        registerChildInGroup(context, [accessToken, groupId, childRegistrationProps]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .registerChildInGroup(accessToken, groupId, childRegistrationProps)
                        .then((childRecord) => {
                            context.commit('REGISTER_CHILD_IN_GROUP', childRecord)
                            resolve(childRecord)
                        })
                        .catch(() => reject('An internal issue has prevented the child record from being added to the group. Please try again later.'))
                }
            )
        },
        updateGroup(context, [accessToken, groupId, updatedProps]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .updateGroup(accessToken, groupId, updatedProps)
                        .then((group) => {
                            context.commit('UPDATE_GROUP', group)
                            resolve(group)
                        })
                        .catch(() => reject('An internal issue has prevented the group from being updated. Please try again later.'))
                }
            )
        },
        deleteGroup(context, [accessToken, groupId]) {
            return new Promise(
                (resolve, reject) => {
                    ApiService
                        .deleteGroup(accessToken, groupId)
                        .then(() => {
                            context.commit('DELETE_GROUP', groupId)
                            resolve()
                        })
                        .catch(() => reject('An internal issue has prevented the group from being deleted. Please try again later.'))
                }
            )
        },
        findKidsInGroup(context, [accessToken, groupId]) {
            context.commit('RESET_KIDS')
            ApiService
                .findKidsInGroup(accessToken, groupId)
                .then((kids) => context.commit('SET_KIDS', kids))
        },
        addGroup(context, [accessToken, groupName, groupIdx]) {
            ApiService
                .addGroup(accessToken, groupName, groupIdx)
                .then((group) => {
                    if (group.isNew === true) {
                        context.commit('ADD_GROUP', group)
                    } else {
                        console.warn('Attempted to re-create existing group.', group)
                    }
                })
        },
        fetchAllGroups(context, accessToken) {
            ApiService
                .fetchAllGroups(accessToken)
                .then((groups) => context.commit('FETCH_ALL_GROUPS', groups))
        }
    },
    modules: {}
})
