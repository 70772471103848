import Vue from 'vue'
import App from './App.vue'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import VueKeyCloak from '@dsb-norge/vue-keycloak-js'
import VueLogger from 'vuejs-logger'
import "vue-simple-context-menu/dist/vue-simple-context-menu.css";
import VueSimpleContextMenu from "vue-simple-context-menu";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import store from './store'
import router from './router'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import VueMatomo from 'vue-matomo'

Vue.component("vue-simple-context-menu", VueSimpleContextMenu);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = process.env.VUE_APP_BUILD_FOR_PROD

Sentry.init({
  Vue,
  dsn: "https://907a818576564f3cb77edf403eb1f2e4@o1125895.ingest.sentry.io/6188584",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "qr-admin.owenclc.com"],
    }),
  ],
  tracesSampleRate: process.env.VUE_APP_SENTRY_SAMPLE_RATE,
});

const options = {
  isEnabled: true,
  logLevel : Vue.config.productionTip ? 'error' : 'debug',
  stringifyArguments : false,
  showLogLevel : true,
  showMethodName : true,
  separator: '|',
  showConsoleColors: true
};

Vue.use(VueLogger, options);

Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: process.env.VUE_APP_MATOMO_URL,
  siteId: process.env.VUE_APP_MATOMO_SITE_ID,

  trackerFileName: 'matomo',

  router: router,

  enableLinkTracking: true,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: !Vue.config.productionTip,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: '*.owenclc.com',

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: 'localhost,qr-admin.owenclc.com',
  preInitActions: [],
  trackSiteSearch: false
});

Vue.use(VueKeyCloak, {
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false
  },
  config: {
    realm: 'Owenclc',
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'owenclc-client'
  },
  onReady: () => {
    console.log('User is logged in, initializing components.')
    /* eslint-disable no-new */
    new Vue({
      store,
      router,
      render: h => h(App)
    }).$mount('#app')
  }
})


