<template>
  <div class="home">
    <top-nav />
    <groups v-if="!inProgress && $store.state.userAgreedToTerms" />
    <b-spinner v-if="inProgress" />
    <div><b-link target="_blank" href="https://www.termsfeed.com/live/34b09530-4ebe-4749-86dc-6270a9c0d62c">Privacy Policy</b-link></div>
    <b-modal id="termsModal" title="Terms and conditions" ok-title="Accept" cancel-title="Reject" @ok="acceptConditions" @cancel="$keycloak.logoutFn()"
             no-close-on-backdrop no-close-on-esc hide-header-close>
      Please accept <b-link target="_blank" href="https://www.termsfeed.com/live/bfbacb9e-6605-4d40-98a5-74f3495e15b5">Terms & Conditions</b-link>.
    </b-modal>
  </div>
</template>

<script>
import TopNav from '@/components/TopNav'
import Groups from '@/components/Groups'

export default {
  name: 'Home',
  components: {
    TopNav, Groups
  },
  data() {
    return {
      inProgress: false,
      intervalHandle: null,
      year: new Date().getFullYear()
    }
  },
  methods: {
    acceptConditions() {
      this.inProgress = true
      this.$store.dispatch('noteUserAgreement', this.$keycloak.token).then(() => this.startPollingForUpdates()).finally(() => this.inProgress = false)
    },
    startPollingForUpdates() {
      this.intervalHandle = setInterval(() => this.$store.dispatch('findStatusUpdates', this.$keycloak.token), 10_000)
    }
  },
  mounted() {
    this.inProgress = true
    this
        .$store
        .dispatch('loadProfile', this.$keycloak.token)
        .then((profile) => {
          if (profile.acceptedTerms === false) {
            this.$bvModal.show('termsModal')
          } else {
            this.startPollingForUpdates()
          }
        })
        .finally(() => this.inProgress = false)
  },
  unmounted() {
    if (this.intervalHandle) {
      clearInterval(this.intervalHandle)
    }
  }
}
</script>

<style scoped>

.home {
  height: auto;
}

</style>
