const BASE_URL = process.env.VUE_APP_API_BASE_URL



export default {
    handleAuthenticationRequired(reject) {
        reject('Authentication required')
    },
    notifyUserAgreedToTerms(accessToken) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/profiles/terms/accepted', {
                    method: 'put',
                    headers: {'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Failed to parse JSON response from the call to accept terms.', err)
                                    reject('Got profile info, but failed converting it to JSON.')
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to accept user terms.')
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to accept terms.', err)
                        reject()
                    })
            }
        )
    },
    loadProfile(accessToken) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/profiles', {
                    method: 'get',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Failed to parse JSON response.', err)
                                    reject('Got profile info, but failed converting it to JSON.')
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            reject('API responded with ' + response.status + ' when trying to fetch profile info.')
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to load user profile.', err)
                        reject('Failed to load profile.')
                    })
            }
        )
    },
    findStatusUpdates(accessToken, clientStatuses) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/kids/updates', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken},
                    body: JSON.stringify(clientStatuses)
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Got kids status updates, but failed to parse the response.', err)
                                    reject()
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to fetch kids status updates.', clientStatuses)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to get status updates.', clientStatuses, err)
                        reject()
                    })
            }
        )
    },
    regenerateCode(accessToken, childId) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/kids/' + childId + '/code', {
                    method: 'post',
                    headers: {'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Got child code regeneration response, but failed converting it to JSON.', err)
                                    reject()
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to regenerate code for child ' + childId + '.')
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to regenerate code for ' + childId + '.', err)
                        reject()
                    })
            }
        )
    },
    moveChildToGroup(accessToken, groupId, childId) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups/' + groupId + '/kids/' + childId, {
                    method: 'put',
                    headers: {'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            resolve()
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to move child ' + childId + ' to group ' + groupId)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to move chile ' + childId + ' to group' + groupId + '.', err)
                        reject()
                    })
            }
        )
    },
    updateChildInfo(accessToken, childId, updatedProps) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/kids/' + childId, {
                    method: 'put',
                    body: JSON.stringify(updatedProps),
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Got update child registration response, but failed converting it to JSON due to ' + err)
                                    reject()
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to update child registration ' + childId, updatedProps)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to update child registration ' + childId + '.', err)
                        reject()
                    })
            }
        )
    },
    removeChildRegistration(accessToken, childId) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/kids/' + childId, {
                    method: 'delete',
                    headers: {'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            resolve()
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to delete child registration ' + childId)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to delete child registration ' + childId + '.', err)
                        reject()
                    })
            }
        )
    },
    registerChildInGroup(accessToken, groupId, childRegistrationProps) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups/' + groupId + '/kids', {
                    method: 'post',
                    body: JSON.stringify(childRegistrationProps),
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Got register child response, but failed converting it to JSON due to ' + err)
                                    reject()
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to register child in group ' + groupId, childRegistrationProps)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to register child in group ' + groupId + '.', childRegistrationProps, err)
                        reject()
                    })
            }
        )
    },
    updateGroup(accessToken, groupId, updatedProps) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups/' + groupId, {
                    method: 'put',
                    body: JSON.stringify(updatedProps),
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => {
                                    console.error('Got update group response, but failed converting it to JSON due to ' + err)
                                    reject()
                                })
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to update group ' + groupId, updatedProps)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to update group ' + groupId + '.', err)
                        reject()
                    })
            }
        )
    },
    deleteGroup(accessToken, groupId) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups/' + groupId, {
                    method: 'delete',
                    headers: {'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            resolve()
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            console.error('API responded with ' + response.status + ' when trying to delete group ' + groupId)
                            reject()
                        }
                    })
                    .catch((err) => {
                        console.error('Failed to delete group ' + groupId + '.', err)
                        reject()
                    })
            }
        )
    },
    findKidsInGroup(accessToken, groupId) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups/' + groupId + '/kids', {
                    method: 'get',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => reject('Got group kids response, but failed converting it to JSON due to ' + err))
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            reject('API responded with ' + response.status + ' when trying to fetch all kids in the group ' + groupId)
                        }
                    })
                    .catch((err) => reject('Failed to get all kids in group ' + groupId + ' due to ' + err))
            }
        )
    },
    addGroup(accessToken, groupName, groupIdx) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups', {
                    method: 'post',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken},
                    body: JSON.stringify({ name: groupName, idx: groupIdx })
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => reject('Got the add group response, but failed converting it to JSON due to ' + err))
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            reject('API responded with ' + response.status + ' when trying to add group ' + groupName + '.')
                        }
                    })
                    .catch((err) => reject('Failed to add group ' + groupName + ' due to ' + err))
            }
        )
    },
    fetchAllGroups(accessToken) {
        return new Promise(
            (resolve, reject) => {
                fetch(BASE_URL + '/groups', {
                    method: 'get',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + accessToken}
                })
                    .then((response) => {
                        if (response.status === 200) {
                            response
                                .json()
                                .then(resolve)
                                .catch((err) => reject('Got the response, but failed converting it to JSON due to ' + err))
                        } else if (response.status === 401) {
                            this.handleAuthenticationRequired(reject)
                        } else {
                            reject('API responded with ' + response.status + ' when trying to fetch all groups.')
                        }
                    })
                    .catch((err) => reject('Failed to get all groups due to ' + err))
            }
        )
    }
}
