<template>
  <div>
    <b-navbar toggleable="lg" type="light" variant="warning" class="font-weight-bold">
      <b-navbar-brand href="#" class="text-white">Owen CLC Daycare Administration</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em title="Employee Name" class="person-title">{{fullName}}</em>
            </template>
            <b-dropdown-item :href="$keycloak.createLogoutUrl()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'TopNav',
  props: {
  },
  data() {
    return {
    }
  },
  methods: {

  },
  computed: {
    fullName() {
      return this.$keycloak.fullName
    }
  }
}
</script>

<style scoped>
.person-title {
  color: beige;
}
</style>
