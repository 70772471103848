<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      intervalHandle: null
    }
  },
  mounted() {
    const loadToken = () => this.$keycloak.token
    this.intervalHandle = setInterval(() => this.$store.dispatch('findStatusUpdates', loadToken()), 10_000)
  },
  unmounted() {
    clearInterval(this.intervalHandle)
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
