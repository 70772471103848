<template>
  <div>
    <b-card no-body>
      <b-tabs pills card vertical>
        <b-tab v-for="(group, idx) in $store.state.groups" :key="idx" lazy>
          <template #title>
            <span @contextmenu.prevent.stop="$refs.groupsContextMenu.showMenu($event, group)"
                  v-b-tooltip.hover.right="'Right Click for more options'">{{ group.name }}</span>
          </template>
          <kids :daycareGroup="group"/>
        </b-tab>
        <template #tabs-end>
          <b-link v-show="!inProgress" @click="openAddGroupModal" title="Add Group" class="mt-2">
            <b-icon-plus-circle v-if="$store.state.groups.length > 0" class="h5" variant="info"/>
            <b-icon-plus-circle v-else class="h5 pulsing" variant="info"/>
          </b-link>
          <b-spinner v-show="inProgress"/>
        </template>
      </b-tabs>
    </b-card>
    <div id="groupModals">
      <b-modal id="addGroupModal" title="Add Group" ok-title="Add" cancel-title="Cancel" @ok="addGroup"
               :ok-disabled="groupInfoIncomplete || inProgress" :cancel-disabled="inProgress">
        <b-container>
          <b-row>
            <b-col>
              <b-form-input v-model="groupName" placeholder="Enter group name here" required></b-form-input>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="updateGroupModal" title="Edit Group" ok-title="Save" cancel-title="Cancel" @ok="updateGroup"
               :ok-disabled="groupInfoIncomplete || inProgress" :cancel-disabled="inProgress">
        <b-container>
          <b-row>
            <b-col>
              <b-form-input v-model="groupName" placeholder="Enter group name here" required></b-form-input>
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
      <b-modal id="deleteGroupModal" title="Confirmation Required" ok-title="Yes" cancel-title="No" @ok="deleteGroup">
        <p class="my-4">
          Are you sure you want to remove <b>{{ groupName }}</b>?
        </p>
        <div v-show="$store.state.kids.length > 0">
          WARNING: Please note that the records of all the children currently assigned to this group will be deleted as
          well.
        </div>
      </b-modal>
    </div>
    <vue-simple-context-menu
        elementId="rightClickMenu"
        :options="[{ name: 'Rename'}, { name: 'Delete'}]"
        ref="groupsContextMenu"
        @option-clicked="groupActionRequested"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Kids from '@/components/Kids'

export default {
  name: 'Groups',
  components: {Kids},
  data() {
    return {
      selectedRawItem: null,
      selectedGroup: null,
      groupName: '',
      groupId: null,
      inProgress: false
    }
  },
  methods: {
    openAddGroupModal() {
      this.resetGroupInfo()
      this.$bvModal.show('addGroupModal')
    },
    deleteGroup() {
      this.inProgress = true
      this
          .$store
          .dispatch('deleteGroup', [this.$keycloak.token, this.groupId])
          .then(() =>
              this.$bvToast.toast(this.groupName + ' has been deleted.', {
                title: `Group deletion success`,
                variant: 'success',
                solid: true,
                autoHideDelay: 1500
              })
          )
          .catch((err) =>
              this.$bvToast.toast(err, {
                title: `Group deletion failed`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
          )
          .finally(() => this.inProgress = false)
    },
    displayDeleteGroupPopup(group) {
      this.initGroup(group)
      this.$bvModal.show('deleteGroupModal')
    },
    updateGroup() {
      this.inProgress = true
      this
          .$store
          .dispatch('updateGroup', [this.$keycloak.token, this.groupId, {name: this.groupName}])
          .then((group) =>
              this.$bvToast.toast(group.name + ' has been updated.', {
                title: `Group update success`,
                variant: 'success',
                solid: true,
                autoHideDelay: 1500
              })
          )
          .catch((err) =>
              this.$bvToast.toast(err, {
                title: `Group update failed`,
                variant: 'danger',
                solid: true,
                autoHideDelay: 3000
              })
          )
          .finally(() => this.inProgress = false)
    },
    resetGroupInfo() {
      this.groupId = ''
      this.groupName = ''
    },
    initGroup(group) {
      this.groupId = group.id
      this.groupName = group.name
    },
    displayEditGroupPopup(group) {
      this.initGroup(group)
      this.$bvModal.show('updateGroupModal')
    },
    groupActionRequested(event) {
      const group = event.item
      const action = event.option.name
      if (action === 'Rename') {
        this.displayEditGroupPopup(group)
      } else if (action === 'Delete') {
        this.displayDeleteGroupPopup(group)
      } else {
        Vue.$log.error('Unrecognized group action ' + action, event)
      }
    },
    addGroup() {
      this.inProgress = true
      this
          .$store
          .dispatch('addGroup', [this.$keycloak.token, this.groupName, this.$store.state.groups.length])
          .then(() => {
            this.groupName = ''
            this.$bvModal.hide('addGroupModal')
          })
          .finally(() => this.inProgress = false)
    }
  },
  computed: {
    groupInfoIncomplete() {
      return !this.groupName || this.groupName.length === 0
    }
  },
  mounted() {
    this.$store.dispatch('fetchAllGroups', this.$keycloak.token)
  }
}

</script>

<style scoped>

.pulsing {
  animation: beat .25s infinite alternate;
  transform-origin: center;
}

@keyframes beat {
  to {
    transform: scale(1.4);
  }
}

</style>
